import { Controller } from "stimulus";
import  MarkerClusterer  from "@google/markerclustererplus";
import  stylesArray  from "../listing_single_map/map_styles";

class NullClusters {
  constructor() {
  }

  clearMarkers() {
  }
}

export default class extends Controller {
  connect() {
    this.desktop = 1200
    this.clientWidth = document.documentElement.clientWidth

    this.googleMapsApiKey = this.element.dataset.googleMapsApiKey
    this.appendScript()

    this.activeClusters = new NullClusters();
    this.activeMap = {}
    this.activeMarkers = []
    this.infoWindows = []

    this.starSvgElement = document.querySelector('.js-Listings__star-icon svg')

    window.addEventListener('plp-map-open', this.constructMap.bind(this))
    window.addEventListener('plp-map-update-markers', this.updateMarkers.bind(this))

  }

  appendScript() {
    if (document.querySelector('#listings-grid-map')) {
      if (this.scriptIsNotAppended()) {
        let googleMapScript = document.createElement('script')

        googleMapScript.type = 'text/javascript'
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&libraries=places,geometry`

        document.body.appendChild(googleMapScript)

      }
    }
  }

  mapIsActive() {
    return Object.keys(this.activeMap).length > 0
  }

  mapIsInactive() {
    return !this.mapIsActive()
  }

  validListingsOnly(listing) {
    return listing.coordinates_present
  }

  updateMarkers() {
    if (this.mapIsActive()) {
      this.activeMarkers.forEach((marker) => {
        marker.setMap(null)
      })

      this.activeClusters.clearMarkers()
      this.fillMap()


      let listingsHeight = document.querySelector('.js-Listings__grid-container').clientHeight
      let listingsWidth = document.querySelector('.js-Listings__grid-container').clientWidth

      let zoomLevel = this.getBoundsZoomLevel(this.mapBounds, {width: listingsWidth, height: listingsHeight})

      this.activeMap.setZoom(zoomLevel);
    }
  }

  getBoundsZoomLevel(bounds, mapDim) {
    let WORLD_DIM = { height: 256, width: 256 };
    let ZOOM_MAX = 21;

    function latRad(lat) {
      let sin = Math.sin(lat * Math.PI / 180);
      let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();

    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    let latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  isDesktopOrGreater() {
    return this.clientWidth >= this.desktop
  }

  fillMap() {
    let listingDataList = JSON.parse(
      document.querySelector('.js-Listings__grid-coordinates').innerHTML
    ).filter(this.validListingsOnly)

    this.mapBounds = new google.maps.LatLngBounds()

    this.activeMarkers = listingDataList.reduce(this.createMarkersCallback.bind(this), [])
    let encoded = window.btoa('<svg version="1.1" viewBox="0.0 0.0 156.0 146.0" fill="none" stroke="none" stroke-linecap="square" stroke-miterlimit="10" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"><clipPath id="p.0"><path d="m0 0l156.0 0l0 146.0l-156.0 0l0 -146.0z" clip-rule="nonzero"/></clipPath><g clip-path="url(#p.0)"><path fill="#000000" fill-opacity="0.0" d="m0 0l156.0 0l0 146.0l-156.0 0z" fill-rule="evenodd"/><path fill="#042f5b" d="m13.275591 73.0l0 0c0 -35.746304 28.978106 -64.72441 64.72441 -64.72441l0 0c17.165977 0 33.62889 6.8191586 45.767067 18.95734c12.138176 12.138182 18.957344 28.601088 18.957344 45.767067l0 0c0 35.746307 -28.978104 64.72441 -64.72441 64.72441l0 0c-35.746304 0 -64.72441 -28.978104 -64.72441 -64.72441z" fill-rule="evenodd"/><path stroke="#ffffff" stroke-width="1.0" stroke-linejoin="round" stroke-linecap="butt" d="m13.275591 73.0l0 0c0 -35.746304 28.978106 -64.72441 64.72441 -64.72441l0 0c17.165977 0 33.62889 6.8191586 45.767067 18.95734c12.138176 12.138182 18.957344 28.601088 18.957344 45.767067l0 0c0 35.746307 -28.978104 64.72441 -64.72441 64.72441l0 0c-35.746304 0 -64.72441 -28.978104 -64.72441 -64.72441z" fill-rule="evenodd"/></g></svg>')
    let inlineSvg = ('data:image/svg+xml;base64,' + encoded)

    this.activeClusters = new MarkerClusterer(
      this.activeMap,
      this.activeMarkers, {
        styles: [{
          width: 40,
          height: 40,
          url: inlineSvg,
          textColor: 'white',
          textSize: 12,
          anchorText: [10,0]
        }]
      }
    );

    this.activeMap.fitBounds(this.mapBounds)
    this.activeMap.setCenter(this.activeMap.getCenter())

    this.activeMap.setOptions({styles: stylesArray()})
  }

  constructMap() {
    if (this.mapIsInactive()) {
      this.activeMap = this.createMap()
      this.fillMap()
    }
  }

  createMarkersCallback(acc, listingData) {
    const bubble = {
      path:
      "m22.363806 29.119423l72.27333 0l0 0c9.468002 0 17.143333 9.348293 17.143333 20.880001c0 11.531704 -7.675331 20.880001 -17.143333 20.880001l-72.27333 0l0 0c-9.468001 0 -17.143333 -9.348297 -17.143333 -20.880001c0 -11.531708 7.675332 -20.880001 17.143333 -20.880001z",
      fillColor: "#00c1dcff",
      strokeColor: "#00c1dcff",
      labelOrigin: new google.maps.Point(60,50),
      fillOpacity: 1,
      scale: .75
    };

    let marker = new google.maps.Marker({
      icon: bubble,
      label: {
        color: 'white',
        fontSize: "14px",
        text: `$${listingData.min_rate}`
      },
      position: {
        lat: listingData.coordinates.latitude,
        lng: listingData.coordinates.longitude
      },
      map: this.activeMap
    })

    this.infoWindowAssignmentCallback(marker, listingData)

    this.mapBounds.extend(marker.position)

    acc.push(marker)

    return acc;
  }


  createMap() {
    return new google.maps.Map(document.querySelector('#listings-grid-map'), {
      center: new google.maps.LatLng(0, 0),
      geocoder: new google.maps.Geocoder(),
      draggable: true,
//      zoomControl: false,
//      mapTypeControl: false,
//      scaleControl: false,
//      streetViewControl: false,
//      rotateControl: false,
//      fullscreenControl: false,
//      disableDefaultUI: true,
    })
  }


  toggleInfoWindowCallback(infoWindow, marker) {
    return () => {
      this.infoWindows.forEach(info => info.close())
      infoWindow.open(this.activeMap, marker);
    }
  }

  infoWindowAssignmentCallback(marker, listingData) {
    let infoWindow = new google.maps.InfoWindow({
      content: this.infoWindowContent(
        listingData
      ),
      padding: 0,
      maxWidth: 300
    })

    this.infoWindows.push(infoWindow)

    marker.addListener('click', this.toggleInfoWindowCallback(infoWindow, marker).bind(this));
  }

  infoWindowContent(listingData) {
    if (listingData.review_is_valid) {
      return `<div class='google_info_window'>
        <div class='ListingsMap__image-container'>
          <img src=${listingData.image_source}>
        </div>
        <a href='${listingData.link_path}' class="ListingSingleMap__link" style="text-decoration: none;" target="_blank" >
          <h3 class='ListingSingleMap__title' > ${listingData.title} </h3>
        </a>
        <div class='ListingsMap__reviews-container'>
          ${this.starSvgElement.outerHTML}
          <span class='ListingSingleMap__reviews-rating'>${listingData.review_rating}</span>
          <span class='ListingSingleMap__reviews-quantity'>(${listingData.reviews_total})</span>
        </div>
        <h5 class='ListingSingleMap__price-information' > Starting $${listingData.min_rate} / night  </h5>
        </div>`
    } else {
      return `<div class='google_info_window'>
        <div class='ListingsMap__image-container'>
          <img src=${listingData.image_source}>
        </div>
        <a href='${listingData.link_path}' style="text-decoration: none;" target="_blank" class="ListingSingleMap__link">
          <h3 class='ListingSingleMap__title' > ${listingData.title}</h3>
          <h5 class='ListingSingleMap__price-information' > From $${listingData.min_rate} / night  </h5>
        </a>
        </div>`
    }

  }

  scriptAppended() {
    return !!document.querySelector('script[src*="https://maps.googleapis.com/maps/api/"]')
  }

  scriptIsNotAppended() {
    return !this.scriptAppended();
  }


}
