import { Controller } from "stimulus";
import store from "components/template_checkout/store";


export default class extends Controller {
  // static targets = [ "addFeeButton" ]

  connect() {
    // NOTE: this does not appear to be used and was breaking JS execution
    // let foundKey = Object.keys(store.getters.optionalFees || {}).find((key) => key.includes(this.addFeeButtonTarget.dataset.id))
    // if (foundKey) {
    //   this.addFeeButtonTarget.innerHTML = 'Added!'
    // }
  }

  addFee(event) {
    // event.preventDefault();
    // let button = event.currentTarget;
    // store.commit('addOptionalFee', {id: button.dataset.id})
    // button.innerHTML = 'Added!'
  }
}

