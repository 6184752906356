import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.desktopBreakPoint = 1200

    this.listingDataList = JSON.parse(
      document.querySelector('.js-Listings__grid-coordinates').innerHTML
    ).filter(this.validListingsOnly)
    document.querySelectorAll('input[type="radio"]').forEach(item => {
      // Use change listener because click listener is firing twice.
      item.addEventListener('change', (e) => {
        if (e.target.checked) {
          e.target.classList.add('is-checked')
        } else {
          e.target.classList.remove('is-checked')
        }
      })
      item.addEventListener('click', event => {
        const uncheckIfChecked = (radio) => {
          const checkedFlag = radio.classList.contains('is-checked')
          if (checkedFlag) {
            radio.checked = false
            radio.classList.remove('is-checked')
          }
        }
        Array.from(document.querySelectorAll(`input[name="${item.name}"]`))
            .filter(node => node.value === item.value)
            .forEach(uncheckIfChecked)
      })
    })
  }

  closeMobileFilter(event) {
    event.preventDefault();
    document
      .querySelectorAll('.template-listings, .template-special-offers')[0]
      .classList
      .toggle('Modal__full-screen')

    document
      .querySelector('body')
      .classList
      .toggle('modal-open')
  }

  clearFilter(event) {
    event.preventDefault()

    const customEvent = new CustomEvent("clear-filter", { bubbles: true});
    this.element.dispatchEvent(customEvent);
  }

  isDesktop() {
    return window.innerWidth >= this.desktopBreakPoint
  }

  applyFilter(event) {
    event.preventDefault()

    const customEvent = new CustomEvent("apply-filter", { bubbles: true});
    this.element.dispatchEvent(customEvent);
  }

  openFilters(event) {
    if (event) {
      event.preventDefault();
    }

    let openFilter = document.querySelector('.Listings__grid-filter-open');

    let filterTitleElement = event.currentTarget
    filterTitleElement.classList.toggle('Listings__pumpkin-underline')
    filterTitleElement.classList.toggle('Listings__arrow-up')

    let filterListElement = filterTitleElement.nextElementSibling
    filterListElement.classList.toggle('Listings__grid-filter-open')

    if (openFilter) {
      openFilter.classList.remove('Listings__grid-filter-open');
      openFilter.previousElementSibling.classList.add('Listings__pumpkin-underline');
      openFilter.previousElementSibling.classList.remove('Listings__arrow-up');
    }
  }

  openSortOptions(event) {
    if (this.isDesktop()) {
      let sortTitleElement = event.currentTarget
      sortTitleElement.classList.toggle('Listings__pumpkin-underline')
      sortTitleElement.classList.toggle('Listings__arrow-up')

      let sortListElement = sortTitleElement.nextElementSibling
      sortListElement.classList.toggle('Listings__grid-filter-open')
    } else {
      this.openFilters()
    }
  }

  openMobileCalendar(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.isDesktop()) {
      const openDesktopCalendarEvent = new CustomEvent("open-popup-calendar", { bubbles: true });
      this.element.dispatchEvent(openDesktopCalendarEvent);
    } else {
      const openMobileCalendarEvent = new CustomEvent("open-mobile-calendar", { bubbles: true });
      this.element.dispatchEvent(openMobileCalendarEvent);
    }
  }

  validListingsOnly(listing) {
    return listing.coordinates_present
  }

  toggleMap(event) {
    event.preventDefault()

    if (this.listingDataList.length > 0) {

      let mapSectionElement = document.querySelector('.js-ListingsMap')

      let listIcon = document.querySelector('.js-Listings__grid-list-icon')
      let mapIcon = document.querySelector('.js-Listings__grid-map-icon')

      listIcon.classList.toggle('Listings__block-hide')
      mapIcon.classList.toggle('Listings__block-hide')

      let listText = document.querySelector('.js-Listings__grid-list-text')
      let mapText = document.querySelector('.js-Listings__grid-map-text')

      listText.classList.toggle('Listings__block-hide')
      mapText.classList.toggle('Listings__block-hide')

      let closeIcon = document.querySelector('.js-Listings__grid-map-close-icon')
      let checkIcon = document.querySelector('.js-Listings__grid-map-check-icon')

      closeIcon.classList.toggle('Listings__block-hide')
      checkIcon.classList.toggle('Listings__block-hide')

      const customEvent = new CustomEvent("plp-map-open", { bubbles: true });
      this.element.dispatchEvent(customEvent);

      mapSectionElement.classList.toggle('ListingsMap')
    } else {
      alert('Listing Data Has No Coordinates!')
    }
  }

  toggleSelectedFilter(event) {
    const customEvent = new CustomEvent("apply-filter", { bubbles: true});
    this.element.dispatchEvent(customEvent);
  }

  updateSortLabel(sortName) {
    Array.from(document.querySelectorAll('.js-Listings__grid-sort-title')).forEach((element) => {
      element.innerHTML = `Sort by ${sortName}`
    })
  }

}
