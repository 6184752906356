import { Controller } from "stimulus";
import {withPublisher} from "mixins/withPublisher";
import { serializeFormData, queryString } from 'init/forms'

export default class extends withPublisher(Controller) {
    static targets = ["form"]
    connect() {
        super.connect();
        this.observe();

        this.listingDataList = JSON.parse(
            document.querySelector('.js-Listings__grid-coordinates').innerHTML
        ).filter(this.validListingsOnly)


        window.addEventListener('apply-filter', this.applyFilterPreventDefault.bind(this))
        window.addEventListener('clear-filter', this.clearFilter.bind(this))
        const desktopDatePicker = document.querySelector('.js-listing-date-picker-desktop-wrapper').querySelector('.listings-date-picker')
        window.addEventListener('open-popup-calendar', () => {
            desktopDatePicker.dispatchEvent(new Event('open-toggle'));
        })

        window.addEventListener('open-filter-toggle', this.stickyFilter);
        window.addEventListener('close-filter-toggle', this.stickyFilter);
    }

    stickyFilter() {
        // if sticky and overlay open
        let filtersSticky = document.querySelector('.sticky')
        let filtersClosed = document.querySelector('.listings-filters-dropdown--close')
        let filtersGrid   = document.querySelector('.listings-filters__grid')
        if (filtersSticky && !filtersClosed && filtersGrid) {
            // remove body overflow
            console.log('-- sticky filter')
            document.body.style.overflowY = 'hidden'
            filtersGrid.classList.add('sticky-filters')
        } else if (filtersGrid) {
            // reapply body overflow
            document.body.style.overflowY = 'auto'
            filtersGrid.classList.remove('sticky-filters')
        }
    }

    observe() {
        if ("IntersectionObserver" in window) {
            let headerHeight = 88
            let barHeight = this.element.offsetHeight
            if (barHeight === 0) return
            const OPTIONS = {
                threshold: .01,
                rootMargin: `-${ headerHeight + barHeight }px`,
            }
            let controller = this
            let observer = new IntersectionObserver(function(entries, observer) {
              entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let sticky = document.querySelector('.sticky')
                    if (sticky) sticky.classList.remove('sticky')
                    let placeholder = document.querySelector('.placeholder-el')
                    if (placeholder) {
                        observer.unobserve(placeholder)
                        placeholder.remove()
                    }
                } else {
                    if (!document.querySelector('.sticky')) {
                        let placeholder = document.createElement('div')
                        placeholder.classList = entry.target.classList
                        placeholder.classList.add('placeholder-el')
                        entry.target.classList.add('sticky')
                        entry.target.after(placeholder)
                        observer.observe(placeholder)
                        controller.stickyFilter()
                    }
                }
              });
            }, OPTIONS);

            observer.observe(this.element)      
          } else {
            // Fallback for IE 11
            // no sticky filter bar for you
          }
    }


    notifySubscribers(event) {
        super.notifySubscribers(event);
        if (event.detail.channel === 'toggleMap') {
            this.toggleMap()
        }
    }

    toggleMap() {
        if (this.listingDataList.length > 0) {

            let mapSectionElement = document.querySelector('.js-ListingsMap')

            const customEvent = new CustomEvent("plp-map-open", { bubbles: true });
            this.element.dispatchEvent(customEvent);

            mapSectionElement.classList.toggle('ListingsMap')
        } else {
            alert('Listing Data Has No Coordinates!')
        }
    }

    validListingsOnly(listing) {
        return listing.coordinates_present
    }

    toggleMobileFilters() {
        document
            .querySelectorAll('.template-listings, .template-special-offers')[0]
            .classList.toggle('Modal__full-screen')

        document
            .querySelector('body')
            .classList
            .toggle('modal-open')
    }

    applyFilterPreventDefault(event) {
        event.preventDefault()
        this.applyFilter()
    }

    applyFilter() {
        let form = this.formTarget
        let data = serializeFormData(form)
        let searchData = this.getSeachFormData()
        let filters = Object.keys(data)
            .filter((key) => key.includes('filters'))
            .reduce((obj, key) => {
                obj[key.replace(/filters|\[|\]/g, '')] = data[key]
                return obj
            }, {})
        if (data.check_in_date_hidden != '' && data.check_out_date_hidden != '') {
            filters.checkin_date = data.check_in_date_hidden
            filters.checkout_date = data.check_out_date_hidden
        }
        if (data.guests !== '' && data.guests !== '0') {
            filters.guests = data.guests
        }

        if (data.bedrooms !== '' && data.bedrooms !== '0') {
            filters.bedrooms = data.bedrooms
        }

        if (searchData.search !== '' && searchData.search !== undefined) {
            filters.search = searchData.search
        }

        let query = queryString(filters)
        if (Object.keys(filters).length) {
            window.location.href = `/stay?${query}`
        } else {
            window.location.href = "/stay"
        }
    }

    // call a search form data, to get data from the form
    // and then call the applyFilter function
    getSeachFormData() {
        let form = document.querySelector('.form--search')
        
        if (form == null) {
            return {}
        }

        let data = serializeFormData(form)

        return data
    }

    clearFilter(event) {
        event.preventDefault();
        window.location.href = "/stay"
    }

}
