import { Controller } from "stimulus";
import Swiper from "swiper";
import { postJson, deleteJson  } from "init/ajax";

export default class extends Controller {
  connect() {

    document.addEventListener('plp-map-open', (event) => {
      console.log('plp-map-open event executed in listing_card_controller')
      this.element.querySelector('.Listings__thumbnails-container').classList.toggle('is-inactive')
    })
  }

  addCompare(event) {
    event.preventDefault();
    var parser = document.createElement('a');
    parser.href = event.currentTarget.href;

    postJson(
      parser.pathname, {
        property_id: parser.href.split('=')[1]
      },
      (response) => {
        let compareModalElement = document.createRange().createContextualFragment(response.html)

        document.querySelector('#content').appendChild(compareModalElement.querySelector('.js-CompareListing__modal'))
      }
    )
  }

}
