import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    this.tabletBreakPoint = 700
    this.clientWidth = document.documentElement.clientWidth

    if (this.isMobile()) {
      new Swiper('.CompareModal__carousel-container', {
        pagination: {
          el: '.swiper-pagination'
        }
      });
    }

    if (this.isTablet()) {
      new Swiper('.CompareModal__carousel-container', {
        spaceBetween: 20,
        freeMode: true,
        forceToAxis: true,
        direction: 'horizontal',
        slidesPerView: 'auto',
        mousewheel: {
          releaseOnEdges: true,
        },
      });
    }
  }

  isMobile() {
    return this.clientWidth < this.tabletBreakPoint
  }

  isTablet() {
    return this.clientWidth >= this.tabletBreakPoint
  }
}
