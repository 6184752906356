import { Controller } from "stimulus";
import {withTogglesOutsideClick} from "mixins/withTogglesOutsideClick";
import {withPopUp} from "mixins/withPopUp";

export default class extends withPopUp(withTogglesOutsideClick(Controller)) {
    static targets = ["dropDownTrigger"]

    connect() {
        super.connect();
        this.dropdownNode = document.querySelector('.js-sort-by-dropdown');
    }

    closeToggle() {
        super.closeToggle();
        this.closeDropDown();
    }

    openToggle() {
        super.openToggle();
        this.openDropDown();
    }

    getDropDownTarget() {
        return this.dropdownNode;
    }

    onOutsideClick(event) {
        if (!this.element.contains(event.target) && !this.getDropDownTarget().contains(event.target)) {
            this.closeToggle();
            this.closeDropDown();
        }
    }
}
