import Flatpickr from "stimulus-flatpickr";
import { serializeFormData, queryString } from "init/forms";
import {withSubscriber} from "mixins/withSubscriber";


export default class extends withSubscriber(Flatpickr) {
  static targets = ['submit', 'form']

  initialize() {
    this.tabletPortrait = 700
    this.tabletLandscape = 1024
    this.desktop = 1200
    this.clientWidth = document.documentElement.clientWidth

    let iconNextString = document.querySelector('.js-BookingCalendar__next-month-arrow img').outerHTML

    let config = {
      clickOpens: false,
      nextArrow: iconNextString,
      prevArrow: iconNextString,
      minDate: document.getElementById('reservation_lead_time').value,
      dateFormat: "m/d/y",
      position: "auto",
      showMonths: 2,
      mode: "range",
      appendTo: document.querySelector('.BookingCalendar__scroll'),
      locale: {
        firstDayOfWeek: 7
      },
      onReady: () => {
        document
          .querySelector('.BookingCalendar__scroll')
          .appendChild(document.querySelector('.js-CalendarModal__form-container'))
      }
    }

    this.config = config

    window.addEventListener('open-mobile-calendar', this.togglePicker.bind(this))
  }

  change(selectedDates, dateStr, instance) {
    this.notify(selectedDates, dateStr, instance);


    selectedDates.forEach((date, index) => {
      if (index === 0) {
        Array.from(document.querySelectorAll('.js-visible-checkin')).forEach((visibleElement) => {
          if (visibleElement.type) {
            visibleElement.value = instance.formatDate(date, "m/d/Y")
          } else {
            visibleElement.innerHTML = instance.formatDate(date, "m/d/Y")
          }

          // visibleElement.classList.add('Listings__blue-font-color')
        })

        Array.from(document.querySelectorAll('.js-checkin-date-hidden')).forEach((hiddenElement) => {
          if (hiddenElement.type) {
            hiddenElement.value = instance.formatDate(date, "Y-m-d")
          } else {
            hiddenElement.innerHTML = instance.formatDate(date, "Y-m-d")
          }
        })
      }

      if (index === 1) {
        let lastNightStay = new Date(date);
        Array.from(document.querySelectorAll('.js-visible-checkout')).forEach((visibleElement) => {
          if (visibleElement.type) {
            visibleElement.value = instance.formatDate(lastNightStay, "m/d/Y")
          } else {
            visibleElement.innerHTML = instance.formatDate(lastNightStay, "m/d/Y")
          }

          // visibleElement.classList.add('Listings__blue-font-color')
        })

        Array.from(document.querySelectorAll('.js-checkout-date-hidden')).forEach((hiddenElement) => {
          if (hiddenElement.type) {
            hiddenElement.value = instance.formatDate(lastNightStay, "Y-m-d")
          } else {
            hiddenElement.innerHTML = instance.formatDate(lastNightStay, "Y-m-d")
          }
        })
      }
    })

    if (selectedDates.length === 2) {

      this.submitTarget.disabled = false;

      Array.from(document.querySelectorAll('.js-Listings__calendar-control-label')).forEach((element) => {
        element.classList.add('Listings__display-none')
      })

      let mobileLabelElement = document.querySelector('.Listings__grid-control-label-mobile')
      // mobileLabelElement.classList.add('Listings__blue-font-color')
    } else {

      let mobileLabelElement = document.querySelector('.Listings__grid-control-label-mobile')
      // mobileLabelElement.classList.remove('Listings__blue-font-color')

      Array.from(document.querySelectorAll('.js-Listings__calendar-control-label')).forEach((element) => {
        element.classList.remove('Listings__display-none')
      })

      Array.from(document.querySelectorAll('.Listings__calendar-input-field')).forEach((element) => {
        // element.classList.remove('Listings__blue-font-color')
      })
    }
  }

  submit(e) {
    e.preventDefault();
    const event = new CustomEvent("apply-filter", {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  onNotification(event) {
    if (event.detail.channel === this.getChannel()) {
      const [selectedDates, dateStr, instance] = event.detail.args;
      if (this.fp && this.fp !== instance) {
        this.fp.setDate(selectedDates);
      }
    }
  }

  search(event) {
    if (event) event.preventDefault();
    let form = this.formTarget
    let filtersForm = document.querySelector('form.Listings__grid-control-bar')
    let filters = serializeFormData(filtersForm)
    filters = Object.keys(filters)
                    .filter(key => key.includes('filters'))
                    .reduce((obj, key) => {
                      obj[key.replace(/filters|\[|\]/g, '')] = filters[key];
                      return obj;
                    }, {});
    let data = serializeFormData(form)
    let searchParams = {
      ...filters,
      checkin_date: data.check_in_date_hidden,
      checkout_date: data.check_out_date_hidden
    }
    searchParams = Object.entries(searchParams).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    let query = queryString(searchParams)
    if (query) query = `?${query}`
    window.location.href = `/stay${query}`
  }

  clearDates(event) {
    if (event) event.preventDefault();
    window.location.href = "/stay"
  }

  formatDateUserFriendly(dateString) {
    let date = new Date(dateString);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
    let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date)

    return `${month} - ${day}`;
  }

  updatePopupCalendar() {
    if (this.fp.selectedDates.length === 2) {
      const customEvent = new CustomEvent("update-popup-calendar", { bubbles: true, detail: this.fp.selectedDates});
      this.element.dispatchEvent(customEvent);
    } else {
      this.fp.clear()
      const updatePopupCalendarEvent = new CustomEvent("update-popup-calendar", { bubbles: true, detail: this.fp.selectedDates});
      this.element.dispatchEvent(updatePopupCalendarEvent);

      const clearInlineCalendarDataEvent = new CustomEvent("clear-inline-calendar-data", { bubbles: true});
      this.element.dispatchEvent(clearInlineCalendarDataEvent);

      this.clearInputCalendarData()

    }
  }

  clearInputCalendarData() {

    Array.from(document.querySelectorAll('.js-visible-checkin')).forEach((visibleElement) => {
      visibleElement.value = ''
    })

    Array.from(document.querySelectorAll('.js-checkin-date-hidden')).forEach((hiddenElement) => {
      hiddenElement.value = ''
    })

    Array.from(document.querySelectorAll('.js-visible-checkout')).forEach((visibleElement) => {
      visibleElement.value = ''
    })

    Array.from(document.querySelectorAll('.js-checkout-date-hidden')).forEach((hiddenElement) => {
      hiddenElement.value = ''
    })
  }

  closeCalendar(event) {
    event.preventDefault()

    document
      .querySelector('.js-BookingCalendar__section')
      .classList
      .remove('open-calendar')

    document
      .querySelector('.js-BookingCalendar__container')
      .classList
      .remove('CalendarModal__section', 'CalendarModal__reveal')

    document
      .querySelector('.js-CalendarModal__form-container')
      .classList
      .remove('CalendarModal__reveal')

    document
      .querySelector('.js-CalendarModal__close-container')
      .classList
      .remove('CalendarModal__reveal')

    document
        .querySelector('body')
        .classList
        .remove('modal-open')


    this.fp.close(event, event.target);
    // this.updatePopupCalendar()
  }

  connect() {
    let checkinElement =  this.element.querySelector(".js-mobile-visible-checkin")
    let checkoutElement =  this.element.querySelector(".js-mobile-visible-checkout")

    Array.from([checkinElement, checkoutElement]).forEach(
      this.setTogglePickerClickEventCallback.bind(this)
    )


    super.connect()
    window.addEventListener('update-full-screen-calendar', this.setCalendarDates.bind(this))
    window.addEventListener('clear-input-calendar-data', this.clearInputCalendarData.bind(this))
    window.addEventListener('open-full-screen-calendar', this.togglePicker.bind(this))

    const initialDates = [];
    // set initial dates if they exist
    if (this.element.hasAttribute("data-initial-checkin-date")) {
      initialDates.push(new Date(this.element.dataset.initialCheckinDate));
    }
    if (this.element.hasAttribute("data-initial-checkout-date")) {
      initialDates.push(new Date(this.element.dataset.initialCheckoutDate));
    }
    this.fp.setDate(initialDates)
  }

  setCalendarDates(data) {
    this.fp.setDate(data.detail || [])
  }

  formatDate(dateString) {
    let date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  dayCreate(dObj, dStr, fp, dayElem) {
    if (dayElem.classList.contains("flatpickr-disabled")) return;
    let today = new Date();
    today = fp.formatDate(today, "Y-m-d");
    const humanDate = dayElem.getAttribute("aria-label");
    const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    let text = "";

    const minDisabled = 2;

    // Typecast `minDisabled` to a String for ie11 which lacks Object.includes()
    if (`${minDisabled}`.includes(formatted)) {
      dayElem.classList.add("flatpickr-disabled");
    }

    if (today === formatted) {
      // eslint-disable-next-line no-param-reassign
      dayElem.innerHTML += `<span class="rate title">Today</span>`;
      dayElem.classList.add('is-today')
    }
  }

  setTogglePickerClickEventCallback(element) {
    element.addEventListener(
      "click",
      this.togglePicker.bind(this)
    );
  }


  togglePicker(event) {
    if (event) {
      event.preventDefault()
    }

    document
      .querySelector('.js-BookingCalendar__section')
      .classList
      .add('open-calendar')


    document
      .querySelector('.js-CalendarModal__close-container')
      .classList
      .add('CalendarModal__reveal')

    document
        .querySelector('.js-BookingCalendar__container')
        .classList
        .add('CalendarModal__section', 'CalendarModal__reveal')

      document
        .querySelector('.js-CalendarModal__form-container')
        .classList
        .add('CalendarModal__reveal')

      document
          .querySelector('body')
          .classList
          .add('modal-open')


    this.fp.open(event, event.target);
  }
}
