import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['container', 'formContainer', 'bookNowBtn', 'closeBtn', 'plusIcon', 'minusIcon', 'seasonalContainer']
  connect() {
    this.checkWindowHeight()
    window.onresize = this.windowResize.bind(this)
    window.addEventListener('open-calendar-modal', this.openCalendarModal.bind(this))
    window.addEventListener('close-calendar-modal', this.closeCalendarModal.bind(this))
  }

  windowResize() {
    if (this.resizeTimer) clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(this.checkWindowHeight.bind(this), 100)
  }

  checkWindowHeight() {
    if (this.isMobile()) {
      this.containerTarget.classList.add('is-mobile')
    } else {
      this.containerTarget.classList.remove('is-mobile')
      if (this.isShort()) {
        this.containerTarget.classList.add('is-short')
      } else {
        this.containerTarget.classList.remove('is-short')
      }

      if (this.isTooShort()) {
        this.containerTarget.classList.add('is-too-short')
      } else {
        this.containerTarget.classList.remove('is-too-short')
      }
    }
  }

  isMobile() {
    return window.innerWidth <= 700
  }

  isShort() {
    return window.innerHeight <= 820
  }

  isTooShort() {
    return window.innerHeight <= 608
  }

  openCalendarModal(event) {
    event.preventDefault()

    this.containerTarget
      .classList
      .add('CalendarModal__section', 'CalendarModal__reveal')

    this.formContainerTarget.classList.add('CalendarModal__reveal')
    this.bookNowBtnTarget.classList.add('CalendarModal__reveal')
    this.closeBtnTarget.classList.add('CalendarModal__reveal')
    document.querySelector('body').classList.add('modal-open')
  }

  closeCalendarModal(event) {
    event.preventDefault()

    this.containerTarget
      .classList
      .remove('CalendarModal__section', 'CalendarModal__reveal')

    this.formContainerTarget.classList.remove('CalendarModal__reveal')
    this.bookNowBtnTarget.classList.remove('CalendarModal__reveal')
    this.closeBtnTarget.classList.remove('CalendarModal__reveal')
    document.querySelector('body').classList.remove('modal-open')
  }

  chooseDates(event) {
    event.preventDefault();
    const fetchRatesEvent = new CustomEvent("fetch-rates", { bubbles: true });
    this.element.dispatchEvent(fetchRatesEvent);
  }

  closeSeasonRates(event) {
    event.preventDefault();
    this.plusIconTarget.classList.add('BookingCalendar__reveal')
    this.minusIconTarget.classList.remove('BookingCalendar__reveal')
    this.seasonalContainerTarget.classList.remove('BookingCalendar__reveal')
  }

  openSeasonRates(event) {
    event.preventDefault();
    this.plusIconTarget.classList.remove('BookingCalendar__reveal')
    this.minusIconTarget.classList.add('BookingCalendar__reveal')
    this.seasonalContainerTarget.classList.add('BookingCalendar__reveal')
  }

  toggleSeasonRates(event) {
    event.preventDefault()
    this.seasonalContainerTarget.classList.toggle('BookingCalendar__reveal')
  }

  clearDates(event) {
    if (event) event.preventDefault();
    window.location.href = window.location.href.split('?')[0];
  }

  submit(event) {
    event.preventDefault()
    this.openCalendarModal(event)
  }
}
