import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    let el = document.getElementById('home_count')
    if (el) this.replaceHomeCount(el.value);
  }

  replaceHomeCount(number) {
    let links = document.evaluate("//a[contains(., 'Homes (')]", document, null, XPathResult.ANY_TYPE, null);
    let link = links.iterateNext();
    link.innerText = `Homes (${number})`;
  }

}