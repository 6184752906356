import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import { getField, updateField } from "vuex-map-fields";
import axios from 'axios';

Vue.use(Vuex)

const formDefaults = {
  optin: true,
  country_name: 'US',
  occupants: '1',
  credit_card_number: '',
  credit_card_cid: '',
  expiry: ''
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  updateField,
  initializeReservation (state, payload) {
    let form = state.form || formDefaults

    state.dates = payload.dates
    state.breakdown = payload.breakdown
    state.listing = payload.listing
    state.form = form
  },
  updateReservation (state, payload) {
    state.breakdown = payload.breakdown
  },
  addOptionalFee (state, payload) {
    let form = state.form || formDefaults
    let request = state.request || {}

    form[`optional_fee_${payload.id}`] = '1'
    request[`optional_fee_${payload.id}`] = 1

    state.request = request
    state.form = form
  },
  updateQuoteRequest (state, payload) {
    state.request = payload
  },
  updateCvv (state, payload) {
    state.form.credit_card_cid = payload
  },
  updateCnum (state, payload) {
    state.form.credit_card_number = payload
  },
  updateExpiry (state, payload) {
    state.form.expiry = payload
  },
  removePromo (state, payload) {
    state.form.coupon_code = ''
  },
  displayErrors (state, payload) {
    state.display_errors = true
    state.errors = payload.errors
    state.errorFields = payload.errorFields
  },
  clearErrors (state, payload) {
    state.display_errors = false
    state.errors = {}
    state.errorFields = []
  },
  dismissErrors (state, payload) {
    state.display_errors = false
  },
  setAppData (state, payload) {
    state.app_data = payload
  },
  initializeConfirmation (state, payload) {
    state.dates = payload.dates
    state.breakdown = payload.breakdown
    state.listing = payload.listing
  },
  transitionConfirmation (state, payload) {
    state.form.credit_card_number = ''
    state.form.expiry = ''
    state.form.credit_card_cid = ''
    state.reservation = payload
  },
  buildReservationRequest (state, payload) {
    let request = state.form || {}
    let formData = payload
    let year, month
    [month, year] = formData.expiry.split('/')
    request.startdate = state.dates.check_in
    request.enddate = state.dates.check_out
    request.unit_id = state.listing.property_id
    // From Streamline documentation:
    //
    // The cellphone associated with the reservation. Most users will use
    // mobile_phone and home_phone interchangebely. If you only receive
    // one phone number, we recommend to use this field as well as
    // home_phone and pass in both. We recommend you pass correct country
    // code as well.
    request.home_phone = request.mobile_phone
    // Since MakeReservation only accepts one address field we concat
    request.address = `${request.address1} ${request.address2}`
    // set CC fields
    request.credit_card_number = formData.credit_card_number
    request.credit_card_cid = formData.credit_card_cid
    request.credit_card_expiration_year = year
    request.credit_card_expiration_month = month
    // return full reservation info
    request.get_reservation_info = true
    request.show_taxes_and_fees = true

    // add authenticity_token for csrf protection
    request.authenticity_token = document.querySelector('[name="csrf-token"]').content
    state.reservation_request = request
  },
  checkoutSubmitting (state, payload) {
    state.checkoutSubmitting = payload
  },
  initializeErrors (state, payload) {
    state.display_errors = false
    state.errors = {}
    state.errorFields = []
  }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  submitCheckout: ({ commit, state }, { parent, component}) => {
    commit('checkoutSubmitting', true)
    component.submitting_key += 1
    axios
      .post('/checkout/reservation', state.reservation_request)
      .then(({ data: response }) => {
        let errorMsg;
        if (Object.keys(response).includes('status')) {
          errorMsg = response.status.description
        } else if (
          Object.keys(response).includes('data') &&
          Object.keys(response.data).includes('reservation') &&
          Object.keys(response.data.reservation).includes('message')
        ) {
          errorMsg = response.data.reservation.message
          if (errorMsg.includes('E1025')) errorMsg = 'Credit Card Declined'
        }

        if (errorMsg) {
          let required = component.$refs.form.querySelectorAll('[required]')
          let clone = required[0].cloneNode(true)
          clone.setCustomValidity(errorMsg);
          commit('displayErrors', { errors: { error: errorMsg }, errorFields: [] })
          component.valid = false
          component.error_key += 1
        } else if (Object.keys(response).includes('data')) {
          let { reservation } = response.data
          commit('transitionConfirmation', reservation)
          window.history.pushState('confirmation', 'Booking Confirmation', `/reservations/${reservation.confirmation_id}`)
          window.dataLayer = window.dataLayer || []
          let dataLayer = JSON.parse(Cookies.get('data_layer'))
          window.dataLayer.push(dataLayer)
          let app_data  = Object.assign({}, state.app_data);
          app_data.step = 'confirmation'
          commit('setAppData', app_data)
          parent.$forceUpdate()
        }
        commit('checkoutSubmitting', false)
        component.submitting_key = 0
      })
  }
}

// getters are functions.
const getters = {
  getField,
  optionalFees: state => {
    return state.form
  },
  checkInDate: state => {
    return state.dates.check_in;
  },
  checkOutDate: state => {
    return state.dates.check_out;
  },
  listing: state => {
    return state.listing
  },
  deposit: state => {
    let { security_deposit: deposits } = state.breakdown.security_deposits
    let deposit = deposits.find(d => d.description === 'Guest Security Deposit')
    return `$${deposit.deposit_required.toFixed(2)}`
  },
  dueToday: state => {
    return `$${state.breakdown.due_today.toFixed(2)}`
  },
  price: state => {
    // since price is immediately reflective of discount, we add it back in
    let price = state.breakdown.base_rental + state.breakdown.coupon_discount
    return `$${price.toFixed(2)}`
  },
  total: state => {
    return `$${state.breakdown.total.toFixed(2)}`
  },
  numDays: state => {
    return `${state.breakdown.days}`
  },
  promoApplied: state => {
    return state.breakdown.coupon_discount > 0
  },
  discount: state => {
    return `- $${state.breakdown.coupon_discount.toFixed(2)}`
  },
  taxes: state => {
    return `$${state.breakdown.base_rental_tax.toFixed(2)}`
  },
  fees: state => {
    return `$${state.breakdown.fee_amount.toFixed(2)}`
  },
  requiredFees: state => {
    return state.breakdown.required_fees
  },
  extrasApplied: state => {
    return state.breakdown.extra_fees > 0
  },
  extraFees: state => {
    return `$${state.breakdown.extra_fees.toFixed(2)}`
  },
  ammountPaid: state => {
    let reservation
    let paid
    if (state.app_data.reservation) {
      reservation = state.app_data.reservation
      paid = reservation.price_paidsum
    }
    return (reservation) ? `$${paid.toFixed(2)}` : null
  }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
  getters,
  actions,
  mutations,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    }),
    // the separate cookie store is used so Rails can access the cookies
    // since it cannot access the window session storage
    createPersistedState({
      storage: {
        getItem: key => {
          Cookies.get(key)
        },
        setItem: (key, value) => {
          Cookies.set(key, value, { expires: 3 })
        },
        removeItem: key => Cookies.remove(key)
      },
      paths: [
        // these values are used in the data_layer which is built server-side
        'breakdown.reservation_days',
        'breakdown.required_fees',
        'form',
        'breakdown.price',
        'breakdown.total',
        'breakdown.taxes',
        'breakdown.coupon_discount'
      ]
    })
  ]
})
